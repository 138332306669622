import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Future Champions
			</title>
			<meta name={"description"} content={"Наша академія прагне виховувати молоді таланти та забезпечувати найвищий рівень футбольної підготовки, гарантуючи, що кожен гравець максимально розкриє свій потенціал."} />
			<meta property={"og:title"} content={"Future Champions"} />
			<meta property={"og:description"} content={"Наша академія прагне виховувати молоді таланти та забезпечувати найвищий рівень футбольної підготовки, гарантуючи, що кожен гравець максимально розкриє свій потенціал."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link">
				Головна
			</Override>
			<Override slot="link2">
				Послуги
			</Override>
			<Override slot="link3">
				Контакти
			</Override>
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-2.jpg?v=2024-06-12T08:12:47.820Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" href="/contacts">
				Контакти
			</Override>
			<Override slot="text1">
				Наші навчальні програми
			</Override>
			<Override slot="text">
				Послуги
			</Override>
			<Override slot="text2">
				Наші послуги спрямовані на охоплення всіх аспектів футбольних тренувань, забезпечуючи всебічний ріст і розвиток. Ми обслуговуємо різні вікові групи та рівні навичок, від молодих претендентів до досвідчених спортсменів, які прагнуть удосконалити свою техніку.
			</Override>
			<Override slot="text3" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					У футбольній академії Future Champions ми прагнемо надавати широкий спектр послуг, спрямованих на покращення кожного аспекту кар’єри футболіста. Від розвитку індивідуальних навичок до командної динаміки та стратегії гри, наша академія допоможе вам провести захоплюючий досвід навчання футболу, розроблений, щоб підняти вашу гру на професійний рівень.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08:12:47.791Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08%3A12%3A47.791Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08%3A12%3A47.791Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08%3A12%3A47.791Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08%3A12%3A47.791Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08%3A12%3A47.791Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08%3A12%3A47.791Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-3.jpg?v=2024-06-12T08%3A12%3A47.791Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-1.jpg?v=2024-06-12T08:12:47.776Z) 0% 0% /cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 2rem 0px" text-align="center" font="--headline2" color="--light">
				Наші основні послуги включають
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08:12:47.770Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-1.jpg?v=2024-06-12T08%3A12%3A47.770Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Розвиток технічних навичок
Контроль м'яча:** Навчіться володіти м'ячем за різних умов.
**Навички дриблінгу:** покращуйте свою спритність і здатність маневрувати у вузьких просторах.
**Точність передач:** Зосередьтеся на точності, щоб зберегти володіння та контролювати хід гри.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08:12:47.773Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-4.jpg?v=2024-06-12T08%3A12%3A47.773Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								**Фізична підготовка
Силові тренування:** Розвивайте м’язову силу, необхідну для вибухової швидкості та витривалості.
**Вправи на спритність:** Покращуйте свою швидкість і рефлекси, важливі для гри на найвищому рівні.
**Підвищення витривалості:** розширте свою здатність працювати на піку протягом усієї гри.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-2.jpg?v=2024-06-12T08:12:47.783Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-2.jpg?v=2024-06-12T08%3A12%3A47.783Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-2.jpg?v=2024-06-12T08%3A12%3A47.783Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-2.jpg?v=2024-06-12T08%3A12%3A47.783Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-2.jpg?v=2024-06-12T08%3A12%3A47.783Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-2.jpg?v=2024-06-12T08%3A12%3A47.783Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-2.jpg?v=2024-06-12T08%3A12%3A47.783Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/2-2.jpg?v=2024-06-12T08%3A12%3A47.783Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								**Тактичні знання
Ігровий інтелект:** Розумійте стратегії та адаптуйтеся до динамічної природи футболу.
**Позиційно-спеціальна тактика:** Спеціальне навчання залежно від вашої ролі в команді.
**Вправи на згуртованість команди:** Виховуйте почуття єдності та командної роботи серед гравців.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-3.jpg?v=2024-06-12T08:12:47.769Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-3.jpg?v=2024-06-12T08%3A12%3A47.769Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-3.jpg?v=2024-06-12T08%3A12%3A47.769Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-3.jpg?v=2024-06-12T08%3A12%3A47.769Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-3.jpg?v=2024-06-12T08%3A12%3A47.769Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-3.jpg?v=2024-06-12T08%3A12%3A47.769Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-3.jpg?v=2024-06-12T08%3A12%3A47.769Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b18e8e2e8e00217cc7bb/images/1-3.jpg?v=2024-06-12T08%3A12%3A47.769Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 2rem"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
					text-transform="uppercase"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Зробіть свій перший крок до футбольної майстерності
					</Strong>
					<br />
					Розкрийте свій потенціал і прискоріть свою футбольну кар'єру в Академії футболу Future Champions. Приєднуйтесь до нас, щоб відчути трансформаційну подорож футбольних тренувань, де ваші прагнення стають вашими досягненнями.
				</Text>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});